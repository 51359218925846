/**
 * @author johan.ahman@svenskaspel.se (Johan Åhman)
 */

import gsap from 'gsap';
import './../stylesheets/ripple.less';

const RippleEffect = class {
  constructor(el, callback) {
    if (window.jQuery && el instanceof window.jQuery) {
      this.el = el.get(0);
    } else if (el instanceof HTMLElement) {
      this.el = el;
    } else {
      this.el = document.querySelector(el);
    }

    if (callback) {
      this.cb = callback;
    }

    this.init();
  }

  init() {
    const rippleEffectTiming = 0.5;
    let cbHasBeenCalled = false;
    let ripple;
    const setUpRipple = () => {
      if (!ripple) {
        ripple = document.createElement('span');
      }

      cbHasBeenCalled = false;

      const backgroundColor = window
        .getComputedStyle(this.el)
        .getPropertyValue('background-color');
      const backgroundIsDark =
        backgroundColor === 'rgba(0, 0, 0, 0)' ?
          false :
          this.isDark(backgroundColor);
      this.el.classList.add('ripple');
      if (!backgroundIsDark) {
        this.el.className += ' ripple-inverted';
      }
    };
    const runRipple = (e) => {
      if (ripple) {
        const rect = this.el.getBoundingClientRect();
        const win = this.el.ownerDocument.defaultView;
        const x = e.pageX - (rect.left + win.pageXOffset);
        const y = e.pageY - (rect.top + win.pageYOffset);
        const w = this.el.offsetWidth * 1.1;

        ripple.className = 'ripple-effect';
        ripple.style.left = `${x}px`;
        ripple.style.top = `${y}px`;

        this.el.appendChild(ripple);

        gsap.fromTo(
          ripple,
          {
            scale: 1,
            opacity: 1
          },
          {
            duration: rippleEffectTiming,
            scale: w,
            opacity: 0,
            ease: 'power0.inOut',
            onComplete: () => {
              if (ripple) {
                if (this.el.contains(ripple)) {
                  this.el.removeChild(ripple);
                }
                this.el.classList.remove('ripple', 'ripple-inverted');
                ripple = null;
              }
            }
          }
        );

        // Fire callback a tad before animation is done
        if (this.cb) {
          setTimeout(() => {
            if (!cbHasBeenCalled) {
              this.cb(e);
            }
            cbHasBeenCalled = true;
          }, rippleEffectTiming * 1000 - 100);
        }
      }
    };

    this.el.onmousedown = () => {
      setUpRipple();
    };
    this.el.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        setUpRipple();
        runRipple(e);
      }
    });
    this.el.onmouseup = (e) => {
      runRipple(e);
    };

  }

  isDark(color) {
    let b; let g; let r;
    if (color.match(/^rgb/)) {
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      color = Number(`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`);
      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    if (hsp > 200) {
      return false;
    }
    return true;
  }
};

export default RippleEffect;
